<template>
	<div class="edit-form-container half-width">
		<!-- :validate="" -->
		<el-form
			ref="itemForm"
			class="item-edit-form 1form-type-2"
			:model="formData"
			:rules="rules"
			label-width="200px"
		>
			<el-form-item label="Email для уведомлений" prop="email">
				<el-input v-model="formData.email" />
			</el-form-item>

			<el-form-item label="Товар дня" prop="product_id">
				<SimpleSpinner :active="productsLoading" />
				<el-select
					v-model="formData.product_id"
					placeholder="Выберите товар"
					filterable
				>
					<el-option
						v-for="item in productsList"
						:key="'product_id-' + item.id"
						:label="item.title_ru"
						:value="item.id"
					/>
				</el-select>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import { required } from '@/constants/validation';
import { itemFormMixin, itemsFetchSetMixin } from '@/mixins';
import { updateFormData } from '@/helpers';

export default {
	mixins: [itemFormMixin, itemsFetchSetMixin],

	props: {
		itemData: {
			type: Object,
			default: () => ({})
		},
		fromAnotherInstance: Boolean
	},

	data() {
		return {
			itemId: null,

			formData: {
				email: '',
				novaposhta_sender_city_id: null,
				novaposhta_sender_contact_ref: null,
				novaposhta_sender_phone_number: null,
				novaposhta_sender_ref: null,
				novaposhta_sender_warehouse_id: null,
				product: null,
				product_id: null
			}
		};
	},

	computed: {
		...mapState({
			productsLoading: state => state.products.isLoading,
			productsList: state => state.products.itemsList
		}),

		navbarSettings: () => ({
			showSaveButton: true,
			showCloseButton: true
		}),

		rules: () => ({
			email: required
		}),

		initialSetFetchSettings: () => [
			{
				action: 'fetch_products',
				payload: { params: { max: -1 } },
				clean_up: 'set_products'
			}
		]
	},

	methods: {
		...mapActions({
			setup_navbar: 'setup_navbar',

			fetch_products: 'products/fetch_products',
			set_products: 'products/set_products'
		}),

		setupForm(itemData, formData) {
			// console.log(updateFormData(itemData, formData))
			const additionalRules = {
				product_id: itemData.product ? itemData.product.id : null
			};
			return updateFormData(itemData, formData, additionalRules);
		}
	},

	created() {
		this.setup_navbar(this.navbarSettings);
	}
};
</script>
