const required = { required: true, trigger: 'blur' };
const number = { type: 'number', trigger: 'blur' };
// const email = { type: 'email', trigger: 'blur' };

/*address: [
	{ required: true, trigger: 'blur' },
	// { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' }
],*/

export { required, number };
